import React from 'react';
import header from '../../images/banners/contact-details.jpeg';

export default function Contact() {
  return (
    <>
      <h1>Contact Details</h1>
      <img src={header} alt="Contact details" class="header" />
      <h2>CONTACT ADDRESS</h2>
      <p>
        <strong>Richard Brabazon </strong>
        <br />
        Rangers Lodge
        <br />
        The Curragh
        <br />
        Co. Kildare
        <br />
        R56 Y443
        <br />
        Ireland
      </p>
      <p>
        Phone: <strong>+353 (0)45 441259</strong>
        <br />
        Fax: <strong>+353 (0)45 441906</strong>
        <br />
        Mobile: <strong>+353 (0)87 2515626</strong>
        <br />
        Email:{' '}
        <strong>
          <a href="mailto:brabazonrichard@gmail.com">
            brabazonrichard@gmail.com
          </a>
        </strong>
      </p>
    </>
  );
}
