import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getWinnersWithYears } from '../api';
import { queryOptions, s3Bucket } from '../constants';
import Spinner from '../components/Spinner';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const thisYear = new Date().getFullYear();

export default function Winners() {
  let params = useParams();

  const currentYear = params.year ? params.year : thisYear;

  const { isLoading, error, data } = useQuery(
    ['winners'],
    getWinnersWithYears,
    queryOptions
  );

  if (isLoading)
    return (
      <div id="content">
        <Spinner></Spinner>
      </div>
    );

  if (error) return 'An error has occurred: ' + error.message;

  return (
    <div id="content">
      <div class="leftNav">
        <ul>
          {data.years && data.years[0] != thisYear ? (
            <li key={`year-${thisYear}`}>
              <NavLink
                className={params.year === undefined ? 'active' : null}
                end
                to={`/winners/${thisYear}`}
              >
                {thisYear} Winners
              </NavLink>
            </li>
          ) : null}

          {data.years.map((year, index) => (
            <li key={`year-${year}`}>
              <NavLink
                className={
                  params.year === undefined &&
                  index === 0 &&
                  data.years[0] == thisYear
                    ? 'active'
                    : null
                }
                end
                to={`/winners/${year}`}
              >
                {year} Winners
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
      <div class="mainContent">
        <h1>{currentYear} WINNERS PRE-TRAINED AT RANGERS LODGE</h1>

        {data[currentYear] && data[currentYear]['header'] ? (
          <div class="header tagline">
            <img
              id="imgBanner"
              class="header tagline"
              src={`${s3Bucket}${data[currentYear]['header'].title}`}
              alt={`${currentYear} Winners`}
            />
            <div class="text">{data[currentYear]['header'].text}</div>
          </div>
        ) : currentYear < 2023 ? (
          <img
            id="imgBanner"
            class="header"
            src={`${s3Bucket}/winners/winner-${currentYear}.jpeg`}
            alt={`${currentYear} Winners`}
          />
        ) : null}

        <div className="winners">
          {!data[currentYear] ? (
            <div>No winners yet in {currentYear} </div>
          ) : (
            Object.entries(data[currentYear]).map(([month, winners], index) => {
              return month === 'header' ? null : (
                <div key={`winners-${currentYear}-${index}`}>
                  <h3>{months[month]}</h3>
                  <ul>
                    {Array.isArray(winners) &&
                      winners.map(winner => (
                        <li
                          key={winner.SK}
                          className={winner.detail.bold ? 'bold' : null}
                        >
                          {winner.title} ({winner.text}) {winner.detail.group}
                        </li>
                      ))}
                  </ul>
                </div>
              );
            })
          )}
        </div>
      </div>
      <div class="clearer"></div>
    </div>
  );
}
