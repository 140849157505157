import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { useQuery } from '@tanstack/react-query';
import { getWinners } from '../../../api';
import { queryOptions } from '../../../constants';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Button, Stack, TextField } from '@mui/material';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    cursor: 'pointer',
  },
}));

export default function WinnersTable() {
  const navigate = useNavigate();

  const { isLoading, error, data } = useQuery(['winners'], getWinners, {
    ...queryOptions,
    onSuccess: data => {
      setFilteredData(data.Items);
    },
  });

  const [filteredData, setFilteredData] = useState(
    data && data.Items ? data.Items : []
  );

  if (isLoading) return 'Loading...';

  if (error) return 'An error has occurred: ' + error.message;

  const handleFilterChange = e => {
    const filter = e.target.value.toString().trim().toLowerCase();
    if (filter === '') {
      setFilteredData(data.Items);
    } else {
      setFilteredData(
        data.Items.filter(winner => {
          if (
            winner.title.toLowerCase().includes(filter) ||
            winner.text.toLowerCase().includes(filter) ||
            (winner.detail &&
              winner.detail.group &&
              winner.detail.group.toLowerCase().includes(filter)) ||
            winner.SK.toLowerCase().includes(filter)
          ) {
            return true;
          }
          return false;
        })
      );
    }
  };

  return (
    <Stack spacing={2}>
      <h1>Winners</h1>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="contained" onClick={() => navigate('add')}>
          Add New Winner
        </Button>
        <TextField
          label="Filter Winners"
          onChange={handleFilterChange}
        ></TextField>
      </Box>
      <Paper sx={{ width: '100%', overflow: 'hidden', maxHeight: '60vh' }}>
        {filteredData && filteredData.length > 0 ? (
          <TableContainer sx={{ maxHeight: '100%' }}>
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              aria-label="sticky table"
            >
              <TableHead sx={{ color: '#fff', backgroundColor: '#666' }}>
                <TableRow>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Location</StyledTableCell>
                  <StyledTableCell>Group</StyledTableCell>
                  <StyledTableCell align="center">Is Bold</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map(winner =>
                  winner.SK.includes('header') ? null : (
                    <TableRow
                      hover
                      key={winner.SK}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      onClick={() =>
                        navigate(`add/${winner.PK}-${winner.SK}`, {
                          state: winner,
                        })
                      }
                    >
                      <StyledTableCell component="th" scope="row">
                        {winner.SK.substring(0, 10)}
                      </StyledTableCell>
                      <StyledTableCell>{winner.title}</StyledTableCell>
                      <StyledTableCell>{winner.text}</StyledTableCell>
                      <StyledTableCell>
                        {winner.detail && winner.detail.group
                          ? winner.detail.group
                          : ''}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {winner.detail && winner.detail.bold ? (
                          <DoneIcon />
                        ) : null}
                      </StyledTableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          'No Winners'
        )}
      </Paper>
    </Stack>
  );
}
