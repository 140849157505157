import React from 'react';

import header from '../../images/banners/about-us.jpeg';

export default function Richard() {
  return (
    <>
      <h1>RICHARD BRABAZON</h1>
      <img src={header} alt="Richard Brabazon" class="header" />
      <p>
        <strong>Richard Brabazon</strong>, son of the legendary jockey, Aubrey,
        and grandson of the classic-winning trainer, Cecil, has spent his
        working life in the bloodstock industry. He gained experience working in
        leading racing stables and studs on The Curragh, at Newmarket and at
        Lambourn as well as short periods in France and Germany.
      </p>
      <p>
        For over two decades Richard has been running his own livery business
        based at Rangers Lodge, The Curragh from where the family has been
        turning out winners since 1927.
      </p>
      <p>
        In recent years Richard has specialised in pre-training at Rangers
        Lodge.
      </p>
    </>
  );
}
