import React, { useState, useReducer } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { toDate } from 'date-fns';

import { useMutation } from '@tanstack/react-query';

import { upsertWinner } from '../../../api';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const today = new Date().toISOString().substring(0, 10);
const initialState = isNew => ({
  name: isNew,
  location: isNew,
  date: false,
});

export default function WinnersAdd() {
  const navigate = useNavigate();
  const location = useLocation();
  const [winner, setWinner] = useState({
    name: (location.state && location.state.title) || '',
    location: (location.state && location.state.text) || '',
    date: (location.state && location.state.SK.substring(0, 10)) || Date.now(),
    bold:
      (location.state && location.state.detail && location.state.detail.bold) ||
      false,
    group:
      (location.state &&
        location.state.detail &&
        location.state.detail.group) ||
      '',
  });
  function reducer(state, action) {
    switch (action.type) {
      case 'name':
        return { ...state, name: action.data };
      case 'location':
        return { ...state, location: action.data };
      case 'date':
        return { ...state, date: action.data };
      default:
        throw new Error();
    }
  }

  const [errors, dispatch] = useReducer(
    reducer,
    initialState(!!!location.state)
  );

  const mutation = useMutation(upsertWinner, {
    onSuccess: (data, variables, context) => {
      window.location.href = '/admin/winners';
    },
  });

  const handleSubmit = () => {
    const sk = location.state
      ? location.state.SK
      : `${new Date(winner.date).toISOString().substring(0, 10)}-${Date.now()}`;

    mutation.mutate({ ...winner, SK: sk });
  };

  const handleChange = (field, val) => {
    setWinner({ ...winner, [field]: val });
    validateWinners(field, val);
  };

  const validateWinners = (field, val) => {
    if (['name', 'location'].includes(field)) {
      dispatch({ type: field, data: val.length === 0 });
    } else if (field === 'date') {
      const d = toDate(val);
      if (d == 'Invalid Date') {
        dispatch({
          type: field,
          data: true,
        });
      } else {
        const dateString = d.toISOString().substring(0, 10);
        dispatch({
          type: field,
          data: !dateString.match(/^2[0-9]{3}-[0-9]{2}-[0-9]{2}$/),
        });
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <h1>Add Winner</h1>

      {mutation.isError
        ? 'An error has occurred: ' + mutation.error.message
        : null}

      <Stack spacing={2}>
        <TextField
          label="Name"
          value={winner['name']}
          variant="standard"
          onChange={e => handleChange('name', e.target.value)}
          required
        />

        <TextField
          label="Location"
          value={winner['location']}
          variant="standard"
          onChange={e => handleChange('location', e.target.value)}
          required
        />
        <Box sx={{ width: '150px' }}>
          <DatePicker
            label="Date *"
            value={winner['date']}
            onChange={newValue => {
              handleChange('date', newValue.$d);
            }}
            renderInput={params => <TextField {...params} />}
            inputFormat="YYYY/MM/DD"
            disableFuture
            required
            disabled={!!location.state}
          />
        </Box>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!winner['bold']}
                onChange={e => handleChange('bold', e.target.checked)}
              />
            }
            label="Bold?"
          />
        </FormGroup>
        <TextField
          label="Group"
          value={winner['group']}
          variant="standard"
          onChange={e => handleChange('group', e.target.value)}
        />
        <Box>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{ mr: '10px' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={
              mutation.isLoading || Object.values(errors).some(val => val)
            }
            onClick={handleSubmit}
          >
            {mutation.isLoading
              ? 'Saving...'
              : location.state
              ? 'Update Winner'
              : 'Add Winner'}
          </Button>
        </Box>
      </Stack>
    </LocalizationProvider>
  );
}
