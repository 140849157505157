import React from 'react';
import { Link, NavLink, Outlet } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import logo from './images/logo.png';
import './css/global.css';

export default function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <div className="site-bg">
        <div id="wrapper">
          <div id="top"></div>
          <div id="middle">
            <div id="header">
              <Link to="/" title="Richard Brabazon Homepage">
                <img src={logo} alt="Richard Brabazon Logo" />
              </Link>
              <div class="tagline">
                Pre-Training Facility
                <br />
                <span>Rangers Lodge, The Curragh, Co. Kildare, Ireland</span>
              </div>
              <div class="nav">
                <ul>
                  <li>
                    <NavLink to="/" className="home">
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/about" className="about">
                      About Us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/facilities" className="facilities">
                      Facilities
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/winners" className="winners">
                      Winners
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/gallery" className="gallery">
                      Gallery
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact" className="contact">
                      Contact Us
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>

            <Outlet />
            <div id="footer">
              Rangers Lodge, The Curragh, Co. Kildare, Ireland
            </div>
          </div>
          <div id="bottom"></div>
        </div>
      </div>
    </QueryClientProvider>
  );
}
