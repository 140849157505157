import React from 'react';
import header from '../../images/banners/our-team.jpeg';

export default function Team() {
  return (
    <>
      <h1>Our Team</h1>
      <img src={header} alt="Our Team header" class="header" />
      <p>
        The riders form the backbone of the staff at Rangers Lodge and yet the
        team extends far beyond them. Among the benefits of the ideal location
        on The Curragh is the proximity of key personnel. Veterinary care,
        physiotherapy, dentistry, spa treatment and farriery, as well as many
        suppliers to the business, are all at close call.
      </p>
      <p>
        The team at Rangers Lodge strive to combine the best of modern
        facilities with the experience of traditional horsemanship, creating an
        environment for the young thoroughbred to develop into the winning
        racehorse.
      </p>
    </>
  );
}
