import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import App from './App';
import Home from './routes/Home';
import AboutIndex from './routes/About/Index';
import Richard from './routes/About/Richard';
import History from './routes/About/History';
import Team from './routes/About/Team';
import Gallery from './routes/Gallery';
import Winners from './routes/Winners';
import ContactIndex from './routes/Contact/Index';
import Contact from './routes/Contact/Contact';
import Map from './routes/Contact/Map';
import AdminIndex from './routes/Admin/Index';
import AdminWinnersIndex from './routes/Admin/Winners/Index';
import AdminWinners from './routes/Admin/Winners/Winners';
import AdminWinnerAdd from './routes/Admin/Winners/WinnerAdd';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="about" element={<AboutIndex />}>
            <Route index element={<Richard />} />
            <Route path="history" element={<History />} />
            <Route path="team" element={<Team />} />
          </Route>
          <Route path="facilities" element={<Gallery />} />
          <Route path="winners" element={<Winners />}>
            <Route path=":year" element={<Winners />} />
          </Route>
          <Route path="gallery" element={<Gallery />} />
          <Route path="contact" element={<ContactIndex />}>
            <Route index element={<Contact />} />
            <Route path="map" element={<Map />} />
          </Route>
          <Route path="contact" element={<Contact />} />
        </Route>
        <Route path="/admin" element={<AdminIndex />}>
          <Route path="winners" element={<AdminWinnersIndex />}>
            <Route index element={<AdminWinners />} />
            <Route path="add" element={<AdminWinnerAdd />}>
              <Route path=":pksk" element={<AdminWinnerAdd />} />
            </Route>
          </Route>
          <Route index element={<Navigate to="winners" replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
