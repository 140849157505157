import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';

export default function ContactIndex() {
  return (
    <div id="content">
      <div class="leftNav">
        <ul>
          <li>
            <NavLink end to="/contact">
              ContactDetails
            </NavLink>
          </li>
          <li>
            <NavLink to="map">Location Map</NavLink>
          </li>
        </ul>
      </div>
      <div class="mainContent">
        <Outlet />
      </div>
      <div class="clearer"></div>
    </div>
  );
}
