import React from 'react';

export default function Map() {
  return (
    <>
      <h1>Location Map</h1>
      {/* <div id="map_canvas" style="width: 475px; height: 365px"></div> */}
      <p>&nbsp;</p>
      <h3>Travelling South on M7</h3>
      <ul>
        <li>Take Exit 12 (Curragh exit).</li>
        <li>Take 1st exit off roundabout (Fionn &amp; his Wolfhounds).</li>
        <li>Horsebox gate immediately on Left Hand Side.</li>
        <li>Cars continue across cattle grid to next gate.</li>
      </ul>
      <h3>Travelling North on M7</h3>
      <ul>
        <li>Take Exit 12 (Curragh exit)</li>
        <li>Take 4th exit off roundabout and cross flyover.</li>
        <li>Take 1st exit off roundabout (Fionn &amp; his Wolfhounds).</li>
        <li>Horsebox gate immediately on Left Hand Side.</li>
        <li>Cars continue across cattle grid to next gate.</li>
      </ul>
    </>
  );
}
