import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';

import TrophyIcon from '@mui/icons-material/EmojiEvents';
import CollectionsIcon from '@mui/icons-material/Collections';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

function AdminNav({ user }) {
  return (
    <List component="nav">
      <NavLink end to="winners">
        <ListItemButton>
          <ListItemIcon>
            <TrophyIcon />
          </ListItemIcon>
          <ListItemText primary="Winners" />
        </ListItemButton>
      </NavLink>
      {user.attributes.email === 'karl.gohery@gmail.com' ? (
        <>
          <NavLink end to="gallery">
            <ListItemButton>
              <ListItemIcon>
                <CollectionsIcon />
              </ListItemIcon>
              <ListItemText primary="Gallery" />
            </ListItemButton>
          </NavLink>
          <NavLink end to="winners">
            <ListItemButton>
              <ListItemIcon>
                <CollectionsIcon />
              </ListItemIcon>
              <ListItemText primary="Facilities" />
            </ListItemButton>
          </NavLink>
        </>
      ) : null}
    </List>
  );
}

export default withAuthenticator(AdminNav);
