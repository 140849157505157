import React from 'react';
import header from '../../images/banners/history.jpeg';

export default function History() {
  return (
    <>
      <h1>History</h1>
      <img src={header} alt="History header" class="header" />
      <p>
        By the time that Cecil Brabazon arrived at Rangers Lodge in 1927 he had
        been champion amateur rider in Ireland three times and had already
        trained an Irish Grand National winner. He added to that success over
        the next three decades with two more Irish Grand National winners
        (Pontet in 1937 and Jack Chaucer in 1940), a Galway Plate victory with
        St Martin in 1941 and consecutive winners of the valuable Red Cross
        Chase (Jack Chaucer 1940, St Martin 1941).
      </p>
      <p>
        On the flat, he trained successfully for Richard Croker (son of the
        legendary ‘Boss’ Croker), sending two runners Celebrator and Corcy to
        finish 5th and 6th in the Epsom Derby of 1932. He won two classics with
        Beau Sabreur, winner of the Irish 2,000 Guineas and St.Leger in 1948. A
        year later Beau Sabreur won the Coronation Cup at Epsom and was the
        first Irish-trained runner in the Prix de l’Arc de Triomphe. At the end
        of a long training career Cecil Brabazon won the Tetrarch Stakes in 1959
        with Sovereign Path, who later went on to become a champion sire.
      </p>
      <p>
        His son, Aubrey, rode many winners trained at Rangers Lodge but is best
        remembered for his unequalled Cheltenham record of three Gold Cups on
        Cottage Rake and two Champion Hurdles on Hatton’s Grace. In the same
        seasons as his great Cheltenham victories, he rode the winners of two
        classics on Masaka (Irish Oaks 1948) and Mighty Ocean (Irish 2,000
        Guineas 1950).
      </p>
      <p>Aubrey later trained successfully at Rangers Lodge for many years.</p>
      <p>
        There is much history behind us at Rangers Lodge – and much more to be
        created…
      </p>
    </>
  );
}
