import React from 'react';

export default function Home() {
  return (
    <div id="content" className="home">
      <iframe
        id="player"
        frameborder="0"
        allowfullscreen="1"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        title="Rangers Lodge Thoroughbred Pre-Training Facility"
        width="862"
        height="485"
        src="https://www.youtube.com/embed/y7hQ_dwCFYE?rel=0&amp;enablejsapi=1&amp;origin=http%3A%2F%2Frichardbrabazon.ie&amp;widgetid=1"
      ></iframe>

      <div class="homeContent">
        <div class="left">
          <h1>RANGERS LODGE</h1>
          <p>
            Rangers Lodge, ideally located beside the world-famous Curragh
            gallops, has a long history as a racing stable. Extensive
            modernisation in recent years has developed the traditional yard
            into a pre-training facility which turns out young horses ready for
            success on the racecourses of the world.
          </p>
        </div>
        <div class="right">
          <h1>PRE-TRAINING FACILITY</h1>
          <p>
            Richard Brabazon and his team at Rangers Lodge offer clients a
            professional pre-training service – the vital early stage of
            education for thoroughbreds, preparing them for the physical and
            mental challenges of training and racing.
          </p>
        </div>
        <div class="clearer"></div>
      </div>
    </div>
  );
}
