import { Auth } from 'aws-amplify';

const baseApiUrl =
  'https://1qn0vqe8ji.execute-api.eu-west-1.amazonaws.com/prod';

export const getImages = type => {
  return fetch(`${baseApiUrl}/images/${type}`).then(response =>
    response.json()
  );
};

export const getWinners = () => {
  return fetch(`${baseApiUrl}/winners`)
    .then(response => response.json())
    .then(response => {
      response.Items = response.Items.reverse();
      return response;
    })
    .catch(ex => console.log(ex));
};

export const getWinnersWithYears = () => {
  return fetch(`${baseApiUrl}/winners`)
    .then(response => response.json())
    .then(data => {
      const winners = data.Items.reduce((acc, winner) => {
        const dateParts = winner.SK.split('-');
        const year = dateParts[0];
        const month =
          dateParts[1] === 'header'
            ? 'header'
            : (Number(dateParts[1]) - 1).toString();

        if (acc[year]) {
          if (acc[year][month]) {
            acc[year][month].push(winner);
          } else {
            acc[year][month] = month === 'header' ? winner : [winner];
          }
        } else {
          acc[year] =
            month === 'header' ? { [month]: winner } : { [month]: [winner] };
        }
        return acc;
      }, {});
      winners['years'] = Object.keys(winners).reverse();
      return winners;
    })
    .catch(ex => console.log(ex));
};

export const upsertWinner = async winner => {
  const data = {
    PK: 'winner',
    SK: winner.SK,
    title: winner.name,
    text: winner.location,
    detail: {
      bold: winner.bold || false,
      group: winner.group,
    },
  };

  const token = await Auth.currentSession().then(res => {
    return res.idToken.getJwtToken();
  });

  return fetch(`${baseApiUrl}/winners`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
    .then(response => {
      return response.json();
    })
    .catch(ex => {
      console.log(ex);
    });
};
