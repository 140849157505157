import React, { useState, useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getImages } from '../api';
import { queryOptions, s3Bucket } from '../constants';
import Spinner from '../components/Spinner';

const pageSize = 12;

export default function Gallery() {
  const isGallery = useMatch('/gallery');
  const [currentImage, setCurrentImage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const { isLoading, error, data } = useQuery(
    [isGallery ? 'gallery' : 'facility'],
    () => getImages(isGallery ? 'gallery' : 'facility', queryOptions),
    queryOptions
  );

  useEffect(() => {
    if (data && data.Items) {
      setTotalPages(Math.ceil(data.Items.length / pageSize));
    }
  }, [data]);

  if (isLoading)
    return (
      <div id="content">
        <Spinner></Spinner>
      </div>
    );

  if (error) return 'An error has occurred: ' + error.message;

  const nextImage = () => {
    if (data && data.Items) {
      if (currentImage < data.Items.length - 1) {
        if (currentImage === currentPage * pageSize + pageSize - 1) {
          setCurrentPage(currentPage + 1);
        }
        setCurrentImage(currentImage + 1);
      } else {
        setCurrentImage(0);
        setCurrentPage(1);
      }
    }
  };

  const prevImage = () => {
    if (data && data.Items) {
      if (currentImage > 0) {
        if (currentPage > 0 && currentImage === currentPage * pageSize) {
          setCurrentPage(currentPage - 1);
        }
        setCurrentImage(currentImage - 1);
      }
    }
  };

  const getThumbClass = index => {
    const classes = [];

    if (index === currentImage) {
      classes.push('selected');
    }

    if (
      !(
        index >= pageSize * currentPage &&
        index < pageSize * currentPage + pageSize
      )
    ) {
      classes.push('hidden');
    }
    return classes.join(' ');
  };

  return (
    <div id="content">
      <div class="galleryWrapper">
        <h2>{isGallery ? 'Gallery' : 'Facilities'}</h2>
        <div id="thumbs" class="navigation">
          <div class="top pagination">
            {totalPages > 0 &&
              Array.from(Array(totalPages).keys()).map(page => {
                if (page === currentPage) {
                  return (
                    <span key={`top-pagination-${page}`} class="current">
                      {page + 1}
                    </span>
                  );
                } else {
                  return (
                    <a
                      key={`top-pagination-${page}`}
                      class="pages"
                      title={page + 1}
                      onClick={() => setCurrentPage(page)}
                      href="#"
                    >
                      {page + 1}
                    </a>
                  );
                }
              })}
          </div>
          <ul class="thumbs">
            {data.Items.map((image, index) => {
              return (
                <li className={getThumbClass(index)} key={index}>
                  <a
                    class="thumb"
                    onClick={() => setCurrentImage(index)}
                    href="#"
                  >
                    <img
                      src={`${s3Bucket}${image.title.slice(
                        0,
                        image.title.lastIndexOf('.')
                      )}_thumb${image.title.slice(
                        image.title.lastIndexOf('.')
                      )}`}
                      alt={`${image.text} thumbnail`}
                    />
                  </a>
                </li>
              );
            })}
          </ul>
          <div class="clearer"></div>
          <div class="bottom pagination">
            {totalPages > 0 &&
              Array.from(Array(totalPages).keys()).map(page => {
                if (page === currentPage) {
                  return (
                    <span key={`bottom-pagination-${page}`} class="current">
                      {page + 1}
                    </span>
                  );
                } else {
                  return (
                    <a
                      key={`bottom-pagination-${page}`}
                      class="pages"
                      title={page + 1}
                      onClick={() => setCurrentPage(page)}
                      href="#"
                    >
                      {page + 1}
                    </a>
                  );
                }
              })}
          </div>
        </div>

        <div id="gallery" class="content">
          <div id="controls" class="controls">
            <div class="nav-controls">
              <a
                onClick={prevImage}
                title="‹ Previous Photo"
                href="#"
                class={currentImage === 0 ? 'hidden' : 'prev'}
              >
                ‹ Previous Photo
              </a>
              <a
                onClick={nextImage}
                title="Next Photo ›"
                href="#"
                class={
                  currentImage === data.Items.length - 1 ? 'hidden' : 'next'
                }
              >
                Next Photo ›
              </a>
            </div>
          </div>
          <div class="slideshow-container">
            <div id="slideshow" class="slideshow">
              <span class="image-wrapper current">
                <a class="advance-link">
                  <img
                    alt={data.Items[currentImage].text}
                    src={`${s3Bucket}${data.Items[currentImage].title.slice(
                      0,
                      data.Items[currentImage].title.lastIndexOf('.')
                    )}_web${data.Items[currentImage].title.slice(
                      data.Items[currentImage].title.lastIndexOf('.')
                    )}`}
                  />
                </a>
              </span>
            </div>
          </div>
          <div class="clearer"></div>
          <div id="caption" class="caption-container">
            <span class="image-caption current">
              <div class="caption">
                <div class="image-title">
                  <span>{data.Items[currentImage].text}</span>
                </div>
              </div>
            </span>
          </div>
          <div class="clearer"></div>
        </div>
        <div class="clearer"></div>
      </div>
    </div>
  );
}
